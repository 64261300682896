<template>
  <SubMainApp>
    <iframe
      sandbox="allow-scripts allow-forms allow-presentation"
      scrolling="no"
      src="https://xsmb247.me/embedded/kq-miennam"
    ></iframe>
    <navbar-mobile class="d-block d-lg-none" v-if="$route.path == '/truc-tiep'" />
  </SubMainApp>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import NavbarMobile from "../components/NavbarMobile";
import SubMainApp from "../layouts/SubMainApp";
export default {
  components: {
    SubMainApp,
    NavbarMobile
  },
  props: [],
  data() {
    return {
      resizeTimeout: null,
      mobileFlag: false,
      saveWidth: null
    };
  },
  computed: {
    isMobile() {
      return isMobile;
    },
    displayRewardList() {
      return isMobile ? this.rewardListMB : this.rewardList;
    }
  },
  mounted() {
    this.$store.commit("setModalLogin", false);
    this.$store.commit("setModalRegister", false);
  },
  created() {
    this.saveWidth = window.innerWidth;
    window.addEventListener("scroll", this.handlerScroll);
    window.onresize = this.handleResize;
    if (this.isReview) {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function() {
        window.history.pushState(null, "", window.location.href);
      };
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handlerScroll);
  },
  methods: {
    handlerScroll() {
      let bottomOfWindow =
        window.scrollY + window.innerHeight + 100 >= document.documentElement.scrollHeight &&
        window.innerWidth <= this.getMobileThresshold();
      if (bottomOfWindow && !this.isLoading) {
        this.loadMore();
      }
    },
    handleResize() {
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      if (this.mobileFlag === isMobile) {
        return;
      }
      this.mobileFlag = isMobile;

      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.$emit("onResize");
      }, 1000);
    },
    loadMore() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        this.$emit("getGameResult");
      }, 100);
    },

    scrollBehavior() {
      document.getElementById("appScroll").scrollIntoView({
        behavior: "smooth"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.loading {
  // background-color: #fff;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
@media (max-width: map-get($grid-breakpoints, "lg")) {
  .loading {
    height: 10vh;
  }
}

iframe {
  border: none;
  height: 1220px;
  width: 100%;
  margin-bottom: -400px;
  overflow-x: hidden;
  overflow-y: hidden;
  pointer-events: none;
  -webkit-transform: scale(0.8);
  -moz-transform-origin: top;
  -webkit-transform-origin: top;
  -o-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

.blue-color {
  color: #00509d !important;
}
</style>
